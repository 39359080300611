import rewardLevelService from '@/api/reward-levels'

export default {
    state: {
        pointsToRedeem: null,
        errorMessage: null,
        isSendingSms: false,
        showRedeemModal: false,
        rewardCart: []
    },
    mutations: {
        setPointsToRedeem(state, points) {
            state.pointsToRedeem = points
        },
        setErrorMessage(state, message) {
            state.errorMessage = message
        },
        setIsSendingSms(state, isSending) {
            state.isSendingSms = isSending
        },
        toggleShowRedeemModal(state) {
            state.showRedeemModal = !state.showRedeemModal
        },
        addRewardToCart(state, reward) {
            const rewardIndex = state.rewardCart.findIndex(r => r.rewardLevel.id === reward.rewardLevel.id)

            if (rewardIndex !== -1) {
                state.rewardCart[rewardIndex].amount++
            } else {
                state.rewardCart.push({
                    rewardLevel: {
                        id: reward.rewardLevel.id,
                        points: reward.rewardLevel.points
                    },
                    amount: 1
                })
            }
        },
        removeRewardFromCart(state, reward) {
            const rewardIndex = state.rewardCart.findIndex(r => r.rewardLevel.id === reward.rewardLevel.id)
            if (rewardIndex !== -1) {
                state.rewardCart[rewardIndex].amount--
            }
        },
        clearRewardCart(state) {
            state.rewardCart = []
        }
    },
    actions: {
        async redeemRewards({ commit, state, dispatch }, errorCodes) {
            commit('setIsSendingSms', true)
            try {
                const response = await rewardLevelService.redeemRewards(state.rewardCart)
                commit('setPointsToRedeem', response.data.pointsRedeemed)
                dispatch('refreshCurrentUser')
                commit('toggleShowRedeemModal')
            } catch (error) {
                commit('setErrorMessage', errorCodes[error.response.data.code])
                commit('toggleShowRedeemModal')
            } finally {
                commit('setIsSendingSms', false)
            }
        }
    }
}
