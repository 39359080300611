
module.exports = {
    publicPath: '/app/',
    transpileDependencies: ['vue-plyr'],
    css: {
        loaderOptions: {
            // by default the `sass` option will apply to both syntaxes
            // because `scss` syntax is also processed by sass-loader underlyingly
            // but when configuring the `data` option
            // `scss` syntax requires an semicolon at the end of a statement, while `sass` syntax requires none
            // in that case, we can target the `scss` syntax separately using the `scss` option
            scss: {
                prependData: `@import "~@/assets/styles/${process.env.VUE_APP_PALETTE ? process.env.VUE_APP_PALETTE : 'white-label'}/main.scss";`
            }
        }
    },
    chainWebpack: config => {
        config.resolve.alias
            .set('plyr', 'plyr/dist/plyr.polyfilled.min.js')
    }

}
