<template>
    <div id="app" class="app-class" :class="{'is-loading': isAppLoading}">
        <div v-if="!hideMobileTopBar" class="sidebar-container">
            <profile-side-bar
                :missions="missions"
                class="profile-side-bar"
            />
        </div>
        <navigation
            v-if="!hideMobileTopBar"
            class="navigation desktop-only"
            :player="player"
            :missions="missions"
        />
        <div
            class="content-container"
            :class="{'image-background': mainBackgroundExists, 'min-content-height': hideMobileTopBar, 'full-content-container': hideMobileTopBar}"
            @click="closeNavbar"
        >
            <transition
                name="fade"
                mode="out-in"
            >
                <router-view :key="path" />
            </transition>
            <!-- <div v-if="!hideCopyright" class="copyright">
                Powered By <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.mpass.gr/"
                >Mpass</a>
            </div> -->
        </div>
        <!-- <div
            class="p-0"
            :class="{'image-background': mainBackgroundExists}"
            fluid
            @click="closeNavbar"
        >
            <transition name="fade" mode="out-in">
                <router-view :key="path" />
            </transition>
        </div> -->
        <badge-modal />
        <task-end-modal />
        <submit-code-modal />
        <redeem-points-modal />
        <survey-submit-modal />
        <!-- TO BE REMOVED AFTER 16/12/2022 -->
        <contest-modal
            v-show="showContestModal"
            @close="closeContestModal"
        />
        <loading-screen v-if="isAppLoading" />
    </div>
</template>
<script>
    import ProfileSideBar from '@/components/ProfileSideBar'
    import Navigation from '@/components/Navigation'
    import config from '@/mixins/config'
    import TaskEndModal from '@/components/TaskEndModal'
    import BadgeModal from '@/components/BadgeModal'
    import SubmitCodeModal from '@/components/SubmitCodeModal'
    import RedeemPointsModal from '@/components/RedeemPointsModal'
    import SurveySubmitModal from '@/components/SurveySubmitModal'
    import LoadingScreen from '@/components/LoadingScreen'
    import ContestModal from '@/components/ContestModal'

    // import eventBus from '@/utils/event-bus'

    export default {
        name: 'App',
        components: {
            ProfileSideBar,
            Navigation,
            TaskEndModal,
            BadgeModal,
            SubmitCodeModal,
            SurveySubmitModal,
            RedeemPointsModal,
            LoadingScreen,
            ContestModal
        },
        mixins: [ config ],
        data() {
            return {
                showContestModal: false // TO BE REMOVED AFTER 16/12/2022
            }
        },
        computed: {
            missions() {
                return this.$store.getters.getMissions
            },
            player() {
                return this.$store.getters.getCurrentUser
            },
            path() {
                return this.$route.path
            },
            isLoggedIn() {
                return this.$store.getters.isUserLoggedIn
            },
            isFirstLogin() {
                // TO BE REMOVED AFTER 16/12/2022
                return this.$store.getters.getFirstLogin
            },
            theming() {
                return this.$store.getters.getTheming
            },
            branding() {
                return this.$store.getters.getBranding
            },
            mainBackgroundExists() {
                return this.$store.getters.mainBackgroundExists
            },
            isAppLoading() {
                return this.$store.getters.getIsAppLoading
            },
            hideMobileTopBar() {
                return this.$route.name === 'tasks' || this.$route.name === 'submit-code' || !this.isLoggedIn
            },
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            },
            isGame() {
                if (!this.task || !this.task.taskType) {
                    return false
                }
                return this.task.taskType.alias.startsWith('GAME')
            },
            hideCopyright() {
                return this.$route.name === 'submit-code' || this.isGame
            }
        },
        watch: {
            isLoggedIn: {
                handler() {
                    this.initData()
                }
            },
            theming: {
                deep: true,
                handler() {
                    this.setInitialCssVars()
                }
            }
        },
        created() {
            this.initData()
        },
        // mounted() {
        //     eventBus.$emit('openBadgeModal', {
        //         badge: {
        //             id: 2,
        //             imageUrl: this.branding.badgeIconUrl,
        //             badgeType: {
        //                 id: 0,
        //                 alias: 'string'
        //             },
        //             metalType: {
        //                 id: 0,
        //                 alias: 'string'
        //             }
        //         },
        //         taskCountFinished: 0,
        //         taskCountTotal: 0,
        //         sumPoints: 0,
        //         sumTotalPoints: 0,
        //         awarded: true
        //     })
        // },
        methods: {
            initData() {
                this.$store.dispatch('fetchConfig')
                if (this.isLoggedIn) {
                    this.$store.dispatch('refreshMissions')
                    this.$store.dispatch('refreshCurrentUser')
                    this.$store.dispatch('fetchMissionInfoList')
                    // TO BE REMOVED AFTER 16/12/2022
                    if (this.isFirstLogin && this.isNotExpired()) {
                        this.showContestModal = true
                    }
                }
            },
            closeContestModal() {
                // TO BE REMOVED AFTER 16/12/2022
                this.showContestModal = false
            },
            setInitialCssVars() {
                const bodyStyles = document.body.style
                bodyStyles.setProperty('--font-family', `${this.theming.fontFamily}, sans-serif`)
                bodyStyles.setProperty('--primary-color', this.theming.primaryColor)
                bodyStyles.setProperty('--secondary-color', this.theming.secondaryColor)
                bodyStyles.setProperty('--light-secondary-color', this.theming.lightSecondaryColor)
                bodyStyles.setProperty('--info-color', this.theming.infoColor)
                bodyStyles.setProperty('--logo-highlight-color', this.theming.logoHighlightColor)
                bodyStyles.setProperty('--light-highlight-color', this.theming.lightHighlightColor)
                bodyStyles.setProperty('--correct', this.theming.correct)
                bodyStyles.setProperty('--error', this.theming.error)
                bodyStyles.setProperty('--main-background-color', this.theming.mainBackgroundColor)
                bodyStyles.setProperty('--background-image-url', 'url(' + this.theming.backgroundImageUrl + ')')
                bodyStyles.setProperty('--main-content-text', this.theming.mainContentText)
                bodyStyles.setProperty('--login-forms-background', this.theming.loginFormsBackground)
                bodyStyles.setProperty('--form-error-color', this.theming.formErrorColor)
                bodyStyles.setProperty('--progress-bar', this.theming.progressBar)
                bodyStyles.setProperty('--sidebar-background', this.theming.sideBarBackground)
                bodyStyles.setProperty('--sidebar-text', this.theming.sideBarText)
                bodyStyles.setProperty('--navigation-background', this.theming.navigationBackground)
                bodyStyles.setProperty('--navigation-text', this.theming.navigationText)
                bodyStyles.setProperty('--survey-very-poor', this.theming.surveyVeryPoor)
                bodyStyles.setProperty('--survey-poor', this.theming.surveyPoor)
                bodyStyles.setProperty('--survey-acceptable', this.theming.surveyAcceptable)
                bodyStyles.setProperty('--survey-very-good', this.theming.surveyVeryGood)
                bodyStyles.setProperty('--survey-excellent', this.theming.surveyExcellent)
                bodyStyles.setProperty('--star-color', this.theming.starColor)
            },
            closeNavbar() {
                const toggler = document.querySelector('#navbarToggler')
                if (toggler && toggler.classList.contains('not-collapsed')) {
                    toggler.click()
                }
            },
            isNotExpired() {
                // TO BE REMOVED AFTER 16/12/2022
                return new Date() < new Date('12/17/2022')
            }
        }
    }
</script>

<style lang="scss">
@import "~@/assets/styles/global.css";

// * {
// scrollbar-width: thin;
// scrollbar-color: gray gray;
// }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 5px;
}

*::-webkit-scrollbar-track {
    background: rgb(108 117 125 / 45%);
}

*::-webkit-scrollbar-thumb {
    background-color: #0b2853;
    border-radius: 10px;
    height: 10px;
    border: 1px solid #0b2853;
}

#app.is-loading {
    overflow-y: hidden !important;
    height: 100vh;
}

.desktop-only {
    display: none !important;
}

.mobile-only {
    display: block !important;
}

.content-container {
    position: relative;
    min-height: calc(100vh - 66px);
    width: 100%;
    background: var(--main-background-color);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    flex-direction: column;
}

.min-content-height {
    min-height: 100vh !important;
}

.image-background {
    // background: var(--background-image-url);
    background: linear-gradient(173deg, rgb(0 0 0 / 5%) 0%, rgb(0 0 0 / 5%) 100%), var(--background-image-url);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}

.copyright,
.copyright a {
    color: var(--main-content-text);
}

.copyright a {
    font-weight: 700;
}

.copyright a:hover {
    color: var(--main-content-text);
    text-decoration: none;
}

.copyright {
    position: absolute;
    bottom: 1%;
    right: 3%;
}

@media screen and (min-width: 992px) {
    .desktop-only {
        display: block !important;
    }

    .mobile-only {
        display: none !important;
    }

    .app-class {
        display: grid;
        grid-template-columns: 25% 75%;
        grid-template-rows: 70px 1fr;
    }

    .sidebar-container {
        grid-column: 1;
        grid-row: span 2;
    }

    .navigation .desktop-only {
        grid-column: 2;
        grid-row: 1;
    }

    .content-container {
        min-height: calc(100vh - 70px);
        grid-column: 2;
        grid-row: 2;
    }

    .full-content-container {
        grid-column: 1 / 3 !important;
        grid-row: 1 / 3 !important;
    }

    .copyright {
        bottom: 0;
        right: 1%;
    }
}

</style>
