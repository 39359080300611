import restApi from '@/utils/rest-api'
import store from '@/store'
import eventBus from '@/utils/event-bus'
export default {
    getMissions() {
        return restApi.get('missions')
    },
    getMissionInfoList: () => {
        return restApi.get('missions/list')
    },
    getMission(id) {
        return restApi.get('missions/' + id)
    },
    awardCertificate(missionId) {
        return restApi.post('missions/' + missionId + '/certificate')
    },
    startTask(missionId, taskId) {
        return restApi.post('missions/' + missionId + '/tasks/' + taskId + '/start')
    },
    getMissionLevels(missionId) {
        return restApi.get('missions/' + missionId + '/levels')
    },
    requestHelpOnTask(missionId, taskId, helpType) {
        return restApi.post('missions/' + missionId + '/tasks/' + taskId + '/help', { helpType }).then(() => {
            store.dispatch('refreshCurrentUser')
        })
    },
    isLastTask(mission, taskId) {
        let index = 0
        for (let task of mission.tasks) {
            if (task.id === taskId) {
                break
            }
            index++
        }
        return index === mission.tasks.length - 1
    },
    async updateTask(mission, task, pointsWon, finished, router) {
        if (!store.getters.isPreviewMode && mission && task && !task.completed) {
            await restApi.post('missions/' + mission.id + '/tasks/' + task.id + '/update', { points: pointsWon, finished })
            store.dispatch('refreshMissions')
            store.dispatch('refreshCurrentUser')

            restApi.post('badges/award-check', { mission: mission.id }).then((res) => {
                if (res.data.awarded) {
                    eventBus.$emit('openBadgeModal', { badge: res.data.badge })
                    store.dispatch('refreshCurrentUser')
                }
            })
            if (this.isLastTask(mission, task.id)) {
                this.awardCertificate(mission.id)
            }
        }

        eventBus.$emit('openTaskEndModal', { task, pointsWon, isLastTask: this.isLastTask(mission, task.id), missionId: mission.id, isPlayAgain: false })
        if (!router) {
            return
        }
        if (mission && mission.id) {
            router.push({ path: `/missions/${mission.id}` })
        } else {
            router.push({ path: `/home` })
        }
    }
}
