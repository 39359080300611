import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import VueI18n from 'vue-i18n'

import Promise from 'promise-polyfill'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'focus-within-polyfill'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowCircleLeft, faChevronLeft, faChevronRight, faPlusCircle, faPaperPlane, faStar, faLock, faBolt, faHome, faCloudUploadAlt, faSearch, faCheck, faHourglassHalf } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import configService from '@/api/config'
import 'es5-shim'
import 'es6-shim'
import 'es6-symbol/implement'

import momentTimezone from 'moment-timezone'
import router from './router'
import store from '@/store'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import VuePlyr from 'vue-plyr'
import Vue2TouchEvents from 'vue2-touch-events'
import requestMixin from '@/mixins/request-mixin'

if (!window.Promise) {
    window.Promise = Promise
}

library.add(faChevronLeft)
library.add(faTimesCircle)
library.add(faCheckCircle)
library.add(faPlusCircle)
library.add(faPaperPlane)
library.add(faStar)
library.add(faLock)
library.add(faBolt)
library.add(faHome)
library.add(faCloudUploadAlt)
library.add(faSearch)
library.add(faCheck)
library.add(faHourglassHalf)
library.add(faArrowCircleLeft)
library.add(faChevronRight)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(BootstrapVue)
Vue.use(Vuex)
Vue.use(VueI18n)
Vue.use(VuePlyr)
Vue.use(momentTimezone)
Vue.use(Vue2TouchEvents)
Vue.mixin(requestMixin)
Vue.use(VueDOMPurifyHTML, {
    default: {
        ALLOWED_TAGS: [
            'iframe',
            'img',
            'br',
            'span',
            'p'
        ],
        ADD_ATTR: ['src', 'allowfullscreen', 'frameborder', 'allow', 'allow-forms', 'allow-same-origin', 'class']
    }
})

initApp()

async function initApp() {
    let messages = { en: {}, el: {} }

    const configResponse = await configService.getConfig()
    if (configResponse.data.locale) {
        messages.en = JSON.parse(configResponse.data.locale.en)
        messages.el = JSON.parse(configResponse.data.locale.el)
    }
    const i18n = new VueI18n({
        locale: store.getters.getLanguage || process.env.VUE_APP_I18N_LOCALE,
        messages
    })

    Vue.config.productionTip = false

    Vue.filter('striphtml', (value) => {
        let div = document.createElement('div')
        div.innerHTML = value
        return div.textContent || div.innerText || ''
    })

    const app = new Vue({
        store,
        router,
        i18n,
        render: h => h(App)
    })
    store.$app = app

    app.$mount('#app')
}
