const getters = {
    isUserLoggedIn: state => state.player.isLoggedIn,
    getFirstLogin: state => state.player.isFirstLogin,
    getCurrentUser: state => state.player.player,
    getLanguage: state => state.app.language,
    isPreviewMode: state => state.app.previewMode,
    isCheatMode: state => state.app.cheatMode,
    isTest: state => state.app.test,
    getRanks: state => state.player.ranks,
    getTotalPlayers: state => state.player.totalPlayers,
    getMissions: state => state.mission.missions,
    getMissionsLoading: state => state.mission.missionsLoading,
    getMission: (state) => (missionId) => state.mission.missions.find(m => m.id === missionId),
    getMissionInfoList: state => state.mission.missionInfoList,
    getSecondsLeft: (state) => (taskId) => {
        if (!state.app.timers[taskId]) {
            return 0
        }
        return state.app.timers[taskId].secondsLeft
    },
    getTask: (state) => (missionId, taskId) => {
        const mission = state.mission.missions.find(m => m.id === missionId)
        if (!mission) {
            return {}
        }
        return mission.tasks.find(t => t.id === taskId)
    },
    isTaskCompleted: (state) => (missionId, taskId) => {
        const mission = state.mission.missions.find(m => m.id === missionId)
        if (!mission) {
            return {}
        }
        return mission.tasks.find(t => t.id === taskId)?.completed
    },
    getTaskIndex: (state) => (missionId, taskId) => {
        const mission = state.mission.missions.find(m => m.id === missionId)
        if (!mission) {
            return -1
        }
        return mission.tasks.findIndex(t => t.id === taskId)
    },
    getNextTaskPath: (state) => (missionId) => {
        missionId = parseInt(missionId)
        const mission = state.mission.missions.find(m => m.id === missionId)
        if (!mission) {
            return
        }
        const nextTask = mission.tasks.find(task => task.next)
        if (nextTask.taskType.alias === 'ACTION_PROFILE') {
            return `/missions/${missionId}/tasks/${nextTask.id}/edit-profile`
        } else if (nextTask.taskType.alias === 'ACTION_LIBRARY') {
            return `/missions/${missionId}/tasks/${nextTask.id}/library`
        } else if (nextTask.taskType.alias === 'ACTION_SURVEY') {
            const nextTaskSurvey = nextTask.surveys[0]
            return `/missions/${missionId}/tasks/${nextTask.id}/surveys/${nextTaskSurvey.id}`
        } else if (nextTask.taskType.alias === 'QUIZ_DAILY') {
            return `/missions/${missionId}/tasks/${nextTask.id}/daily-quiz`
        } else {
            return `/missions/${missionId}/tasks/${nextTask.id}`
        }
    },
    getTaskPath: (state) => (missionId, taskId) => {
        missionId = parseInt(missionId)
        taskId = parseInt(taskId)
        const mission = state.mission.missions.find(m => m.id === missionId)
        if (!mission) {
            return
        }
        const task = mission.tasks.find(t => t.id === taskId)
        if (!task) {
            return
        }
        if (task.taskType.alias === 'ACTION_PROFILE') {
            return `/missions/${missionId}/tasks/${task.id}/edit-profile`
        } else if (task.taskType.alias === 'ACTION_LIBRARY') {
            return `/missions/${missionId}/tasks/${task.id}/library`
        } else if (task.taskType.alias === 'ACTION_SURVEY') {
            const nextTaskSurvey = task.surveys[0]
            return `/missions/${missionId}/tasks/${task.id}/surveys/${nextTaskSurvey.id}`
        } else if (task.taskType.alias === 'QUIZ_DAILY') {
            return `/missions/${missionId}/tasks/${task.id}/daily-quiz`
        } else {
            return `/missions/${missionId}/tasks/${task.id}`
        }
    },
    getConfigFeatures: state => state.app.config.features,
    isPlayAgainEnabled: state => state.app.config.features.playAgainEnabled,
    getTheming: state => state.app.config.theming,
    getBranding: state => state.app.config.branding,
    mainBackgroundExists: state => !!state.app.config.theming.backgroundImageUrl,
    getInCodeLabels: state => state.app.config.inCodeLabels,
    getPlayerProfileFields: state => state.app.config.playerProfileFields,
    getPlayerProfileDropdownFields: state => state.app.config.playerProfileDropdownFields,
    getPointsToRedeem: state => state.rewards.pointsToRedeem,
    getErrorMessage: state => state.rewards.errorMessage,
    isSendingSms: state => state.rewards.isSendingSms,
    getShowRedeemModal: state => state.rewards.showRedeemModal,
    getRewardCart: state => state.rewards.rewardCart,
    getRewardCartTotalPoints: state => {
        const tempPoints = state.rewards.rewardCart.map(r => r.rewardLevel.points * r.amount)
        return tempPoints.reduce((total, points) => total + points, 0)
    },
    getIsAppLoading: state => state.app.isAppLoading
}

export default getters
