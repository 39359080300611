<template>
    <b-modal
        id="badge-modal"
        ref="badge-modal"
        :hide-header="true"
        :hide-footer="true"
        centered
    >
        <b-container class="modal-body">
            <b-button class="close-button" @click="hideModal()"><font-awesome-icon :icon="[ 'far', 'times-circle' ]" /></b-button>
            <b-row>
                <b-col />
            </b-row>
            <b-row>
                <b-col class="logo-center">
                    <b-img
                        :src="branding.primaryLogoUrl"
                        class="w-25 mx-2 pt-2"
                        alt=" "
                    />
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-img
                    v-if="badge.id"
                    :src="badge.imageUrl"
                    class="avatar"
                />
            </b-row>
            <div class="footer-container">
                <div class="win-text">{{ $t('badgeModal.winText') }}</div>
            </div>
        </b-container>
    </b-modal>
</template>

<script>
    import config from '@/mixins/config'
    import eventBus from '@/utils/event-bus'
    export default {
        name: 'BadgeModal',
        mixins: [config],
        data() {
            return {
                badge: {}
            }
        },
        computed: {
            branding() {
                return this.$store.getters.getBranding
            }
        },
        created() {
            eventBus.$on('openBadgeModal', (payload) => {
                if (payload.badge) {
                    this.badge = payload.badge
                    this.$bvModal.show('badge-modal')
                }
            })
        },
        methods: {
            hideModal() {
                this.$refs['badge-modal'].hide()
            }
        }
    }
</script>

<style lang="scss" scoped>
.modal-body {
    background: url("~@/assets/svg/keraunos.svg"), linear-gradient(to right, rgb(0 0 0 / 25%) 0%, rgb(0 0 0 / 25%) 100%), linear-gradient(to right, var(--primary-color) 0%, var(--secondary-color) 100%);
    background-size: 50px 50px, cover, cover;
    background-position: center;
    background-repeat: space, no-repeat, no-repeat;
    min-height: 300px !important;
    top: -32px;
    border-radius: 25px;
    position: relative;
}

.footer-container {
    background: var(--white);
    width: 100%;
    min-height: 240px;
    position: absolute;
    border-radius: 25px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    right: 0;
    bottom: -55px;
    left: 0;
    z-index: 1;
}

.avatar {
    position: absolute;
    width: 120px;
    top: 15%;
    z-index: 2;
}

.logo-center {
    text-align: center;
}

.close-button {
    position: absolute;
    background: none;
    z-index: 3;
    right: 0;
    outline: none;
    border: none;
}

.close-button svg {
    font-size: 40px;
}

.close-button:hover {
    background: none;
    outline: none;
    border: none;
}

.win-text {
    font-weight: 700;
    font-size: 1.25rem;
    color: var(--black);
    text-align: center;
    width: 90%;
    margin: 4rem auto 0;
}

@media screen and (min-width: 998px) {
    .modal-body {
        min-height: 350px !important;
    }

    .avatar {
        width: 170px;
        top: 22%;
        z-index: 2;
    }

    .footer-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 230px;
    }

    .win-text {
        font-size: 1.5rem;
        margin: 2rem auto 0;
    }
}

</style>
