
export default {
    data() {
        return {
            config: {
                host: process.env.VUE_APP_API_HOST_DOMAIN,
                apiUrl: process.env.VUE_APP_API_HOST_DOMAIN + process.env.VUE_APP_API_BASE_PATH,
                gameServerHost: process.env.VUE_APP_GAME_SERVER_HOST,
                s3Url: process.env.VUE_APP_S3_URL
            }
        }
    }
}
