<template>
    <div>
        <b-img
            v-if="!avatar"
            class="avatar"
            :src="branding.defaultAvatarUrl"
            :style="cssVars"
        />
        <b-img
            v-if="avatar && avatar.imageUrl"
            :src="`${avatar.imageUrl}`"
            class="avatar"
            :style="cssVars"
        />
    </div>
</template>
<script>
    export default {
        props: {
            avatar: {
                type: Object,
                default: null
            },
            avatarWidth: {
                type: String,
                default: '100%'
            },
            borderColor: {
                type: String,
                default: 'white'
            },
            maxAvatarWidth: {
                type: String,
                default: null
            }
        },
        computed: {
            cssVars() {
                return {
                    '--width': this.avatarWidth,
                    '--border-color': this.borderColor,
                    '--max-width': this.maxAvatarWidth
                }
            },
            branding() {
                return this.$store.getters.getBranding
            }
        }
    }
</script>
<style lang="scss" scoped>
.avatar {
    border-radius: 50%;
    border: 2px solid var(--border-color);
    width: var(--width);
    max-width: var(--max-width);
}

</style>
