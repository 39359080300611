<template>
    <transition name="fade">
        <div v-if="showModal" class="redeem-modal">
            <b-container class="modal-body">
                <b-button class="close-button" @click="hideModal()"><font-awesome-icon :icon="[ 'far', 'times-circle' ]" /></b-button>
                <b-row>
                    <b-col />
                </b-row>
                <b-row>
                    <b-col class="logo-center">
                        <b-img
                            :src="branding.primaryLogoUrl"
                            class="w-25 mx-2 pt-2"
                            alt=" "
                        />
                    </b-col>
                </b-row>
                <b-row class="justify-content-center">
                    <b-col class="avatar-container">
                        <avatar :avatar="player.avatar? player.avatar : null" />
                    </b-col>
                </b-row>
                <div class="footer-container">
                    <div>
                        <div v-if="pointsToRedeem && !errorMessage" class="points-text">
                            {{ $t('prizes.successMessage', {pointsToRedeem}) }}
                        </div>
                        <div v-else class="points-text">
                            {{ errorMessage }}
                        </div>
                    </div>
                </div>
            </b-container>
        </div>
    </transition>
</template>

<script>
    import config from '@/mixins/config'
    import Avatar from '@/components/Avatar'

    export default {
        components: { Avatar },
        mixins: [config],
        computed: {
            branding() {
                return this.$store.getters.getBranding
            },
            player() {
                return this.$store.getters.getCurrentUser
            },
            pointsToRedeem() {
                return this.$store.getters.getPointsToRedeem
            },
            errorMessage() {
                return this.$store.getters.getErrorMessage
            },
            showModal() {
                return this.$store.getters.getShowRedeemModal
            }
        },
        methods: {
            hideModal() {
                if (!this.errorMessage) {
                    this.$router.push({ path: '/home' })
                }
                this.$store.commit('toggleShowRedeemModal')
                this.$store.commit('setPointsToRedeem', null)
                this.$store.commit('setErrorMessage', null)
            }
        }
    }
</script>

<style lang="scss" scoped>
.redeem-modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: grid;
    place-items: center;
}

.modal-body {
    background: url("~@/assets/svg/keraunos.svg"), linear-gradient(to right, rgb(0 0 0 / 25%) 0%, rgb(0 0 0 / 25%) 100%), linear-gradient(to right, var(--primary-color) 0%, var(--secondary-color) 100%);
    background-size: 50px 50px, cover, cover;
    background-position: center;
    background-repeat: space, no-repeat, no-repeat;
    min-height: 400px !important;
    max-width: 500px !important;
    top: -32px;
    border-radius: 25px;
}

.footer-container {
    background: var(--white);
    width: 100%;
    min-height: 300px;
    position: absolute;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    right: 0;
    bottom: -55px;
    left: 0;
    z-index: 1;
    display: grid;
    place-items: center;
}

.avatar-container {
    position: absolute;
    width: 140px;
    top: 28%;
    z-index: 2;
}

.logo-center {
    text-align: center;
}

.close-button {
    position: absolute;
    background: none;
    z-index: 3;
    right: 0;
    outline: none;
    border: none;
}

.close-button svg {
    font-size: 40px;
}

.close-button:hover {
    background: none;
    outline: none;
    border: none;
}

.points-text {
    font-weight: 700;
    font-size: 1.1rem;
    color: var(--black);
    text-align: center;
    margin: 0 auto;
    padding: 50px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

</style>

<style lang="scss" >
.modal-content {
    background: transparent !important;
    border: none !important;
}
</style>
