<template>
    <transition
        name="fade"
        mode="out-in"
    >
        <div class="backdrop" @click.self="closeModal">
            <div class="contest-modal">
                <img :src="require('@/assets/images/contest-image.png')" alt="contest image">
                <span class="close" @click="closeModal" />
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        methods: {
            closeModal() {
                this.$emit('close')
            }

        }
    }
</script>

<style scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgb(0 0 0 / 70%);
    z-index: 999;
    display: grid;
    place-items: center;
}

.contest-modal {
    width: 90%;
    max-width: 768px;
    position: relative;
}

.close {
    position: absolute;
    right: 1%;
    top: 1%;
    width: 2rem;
    height: 2rem;
    opacity: 1;
    outline: none;
    cursor: pointer;
}

.close:hover {
    opacity: 1;
}

.close::before,
.close::after {
    position: absolute;
    top: 0;
    left: 1rem;
    content: " ";
    height: 2rem;
    width: 2px;
    background-color: #000;
}

.close::before {
    transform: rotate(45deg);
}

.close::after {
    transform: rotate(-45deg);
}

.contest-modal img {
    width: 100%;
    height: auto;
}

.fade-enter-active,
.fade-leave-active {
    transition-property: opacity;
    transition-duration: 0.25s;
}

.fade-enter-active {
    transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

@media screen and (min-width: 992px) {
    .contest-modal {
        width: 100%;
        max-width: 550px;
    }
}

</style>
