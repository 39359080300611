import missionService from '@/api/mission'
export default {
    state: {
        missions: [],
        missionsLoading: false,
        missionInfoList: []
    },
    mutations: {
        setMissions(state, missions) {
            state.missions = missions
        },
        setMissionInfoList(state, missionInfoList) {
            state.missionInfoList = missionInfoList
        },
        setMissionsLoading(state, loading) {
            state.missionsLoading = loading
        }
    },
    actions: {
        fetchMissionInfoList({ commit }) {
            missionService.getMissionInfoList().then(missionInfoList => {
                commit('setMissionInfoList', missionInfoList)
            })
        },
        refreshMissions({ commit }) {
            commit('setMissionsLoading', true)
            missionService.getMissions().then((res) => {
                let missions = res.data
                missions.sort((a, b) => {
                    return (a.position || Number.MAX_VALUE) - (b.position || Number.MAX_VALUE)
                })
                missions.sort((a, b) => {
                    return a.locked - b.locked
                })
                commit('setMissions', missions)
                commit('setMissionsLoading', false)
            })
        }
    }
}
