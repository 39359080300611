import restApi from '@/utils/rest-api'

export default {
    getCurrentPlayer: () => {
        return restApi.get('players/current')
    },
    updateCurrentPlayer: (player) => {
        return restApi.put('players/current', player)
    }
}
