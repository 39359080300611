<template>
    <b-modal
        id="submit-survey-modal"
        ref="submit-survey-modal"
        class="modal-content"
        :hide-header="true"
        :hide-footer="true"
        centered
    >
        <b-container class="modal-body">
            <b-button class="close-button" @click="hideModal()"><font-awesome-icon :icon="[ 'far', 'times-circle' ]" /></b-button>
            <b-row>
                <b-col class="logo-center">
                    <b-img
                        :src="branding.primaryLogoUrl"
                        class="mx-2 pt-2"
                        alt=" "
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="mt-1 text-alias">{{ $t('submitSurveyModal.topTextFirst') }}</div>
                    <div class="mt-1 text-alias">{{ $t('submitSurveyModal.topTextSecond') }}</div>
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-col class="avatar-container">
                    <avatar :avatar="player.avatar? player.avatar : null" />
                </b-col>
            </b-row>
            <div class="footer-container">
                <div>
                    <div class="win-lose-text">{{ $t('submitSurveyModal.title') }}</div>
                    <div class="points-text mt-3">{{ $t('submitSurveyModal.textFirst') }}</div>
                    <div class="points-text-alias mt-1">"{{ surveyAlias }}"</div>
                    <div class="points-text">{{ $t('submitSurveyModal.textSecond') }}</div>
                    <div class="help-div mt-4">
                        <b-button class="show-result" @click="hideModal()">{{ $t('submitSurveyModal.buttonText') }}</b-button>
                    </div>
                </div>
            </div>
        </b-container>
    </b-modal>
</template>

<script>
    import config from '@/mixins/config'
    import eventBus from '@/utils/event-bus'
    import Avatar from '@/components/Avatar'

    export default {
        name: 'SurveySubmitModal',
        components: { Avatar },
        mixins: [config],
        data() {
            return {
                surveyAlias: ''
            }
        },
        computed: {
            player() {
                return this.$store.getters.getCurrentUser
            },
            branding() {
                return this.$store.getters.getBranding
            }
        },
        created() {
            eventBus.$on('openSurveySubmitModal', (payload) => {
                this.$bvModal.show('submit-survey-modal')
                this.surveyAlias = payload.surveyAlias
            })
        },
        methods: {
            hideModal() {
                this.$refs['submit-survey-modal'].hide()
            }
        }
    }
</script>

<style lang="scss" scoped>
.modal-body {
    background: url("~@/assets/svg/keraunos.svg"), linear-gradient(to right, rgb(0 0 0 / 25%) 0%, rgb(0 0 0 / 25%) 100%), linear-gradient(to right, var(--primary-color) 0%, var(--secondary-color) 100%);
    background-size: 50px 50px, cover, cover;
    background-position: center;
    background-repeat: space, no-repeat, no-repeat;
    min-height: 400px !important;
    max-width: 500px !important;
    top: -32px;
    border-radius: 25px;
}

.footer-container {
    background: var(--white);
    width: 100%;
    min-height: 300px;
    position: absolute;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    right: 0;
    bottom: -55px;
    left: 0;
    z-index: 1;
}

.avatar-container {
    text-align: center;
    max-width: 150px;
    position: absolute;
    top: 41%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.logo-center {
    text-align: center;
}

.logo-center img {
    width: 150px;
    height: auto;
}

.text-alias {
    text-align: center;
    color: var(--white);
    font-weight: 700;
    font-size: 13px;
}

.close-button {
    position: absolute;
    background: none;
    z-index: 3;
    right: 0;
    outline: none;
    border: none;
}

.close-button svg {
    font-size: 40px;
}

.close-button:hover {
    background: none;
    outline: none;
    border: none;
}

.win-lose-text {
    font-weight: 700;
    font-size: 20px;
    color: var(--black);
    text-align: center;
    margin-top: 80px;
}

.points-text-alias {
    font-weight: 700;
    font-size: 17px;
    color: var(--black);
    text-align: center;
}

.points-text {
    font-weight: 700;
    font-size: 14px;
    color: var(--black);
    text-align: center;
}

.help-div {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.show-result {
    font-weight: 700;
    font-size: 14px;
    color: var(--white);
    text-align: center;
    background: var(--secondary-color);
    border-radius: 15px;
    border: none;
    transition: all 0.25s ease-in-out;
}

.show-result:hover {
    background: var(--info-color);
}

.show-result:active {
    transform: scale(0.95);
}

</style>

<style lang="scss" >
.modal-content {
    background: transparent !important;
    border: none !important;
}
</style>
