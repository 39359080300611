import { login } from '@/api/login'
import playerService from '@/api/player'
import rankService from '@/api/rank'
import router from '@/router'
export default {
    state: {
        isLoggedIn: localStorage.getItem('api-token'),
        isFirstLogin: false,
        player: {},
        ranks: [],
        totalPlayers: 0
    },
    mutations: {
        login(state, token) {
            state.isLoggedIn = true
            localStorage.setItem('api-token', token)
        },
        logout(state) {
            state.player = {}
            localStorage.removeItem('api-token')
        },
        setIsFirstLogin(state, value) {
            state.isFirstLogin = value
        },
        setPlayer(state, player) {
            state.player = player
        },
        setRanks(state, ranks) {
            state.ranks = ranks
        },
        setTotalPlayers(state, totalPlayers) {
            state.totalPlayers = totalPlayers
        }
    },
    actions: {
        login({ commit, dispatch }, credentials) {
            return new Promise((resolve, reject) => {
                login({
                    username: credentials.username,
                    password: credentials.password
                }).then(response => {
                    commit('login', response.data.token)
                    commit('setIsFirstLogin', true)
                    resolve(response)
                }).catch(error => {
                    if (error.response && [403, 401].includes(error.response.status)) {
                        dispatch('logout')
                        router.push({ path: `/login` })
                    }
                    reject(error)
                })
            })
        },
        logout({ commit }) {
            commit('logout')
        },
        refreshCurrentUser({ commit, dispatch }) {
            playerService.getCurrentPlayer().then((res) => {
                let player = res.data
                commit('setPlayer', player)
            }).catch((error) => {
                if (error.response && [403, 401].includes(error.response.status)) {
                    dispatch('logout')
                    router.push({ path: `/login` })
                }
            })
            rankService.getRanks().then((res) => {
                let ranks = res.data.ranks
                commit('setTotalPlayers', res.data.totalPlayers)

                for (let rank of ranks) {
                    rank.isActive = true
                }
                commit('setRanks', ranks)
            })
        }
    }
}
