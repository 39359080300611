<template>
    <b-container v-if="badges && badges.length > 0" class="container-badge" fluid>
        <div class="badge-text rounded-pill">
            <span>x{{ badges.length }}</span>
        </div>
        <img :src="`${badges[0].badge.imageUrl}`">
    </b-container>
</template>

<script>
    import config from '@/mixins/config'

    export default {
        name: 'Badge',
        mixins: [ config ],
        props: {
            badges: {
                type: Array,
                default: () => []
            }
        }
    }
</script>

<style lang="scss" scoped>
.container-badge {
    width: auto;
    height: auto;
    position: relative;
}

.container-badge img {
    width: auto;
    height: auto;
}

.badge-text {
    background: var(--error);
    color: var(--white);
    float: right;
    position: absolute;
    right: 30%;
    top: 0;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 700;
    font-size: 0.8rem;
}
</style>
