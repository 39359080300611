import configService from '@/api/config'
export default {
    state: {
        language: localStorage.getItem('language'),
        previewMode: localStorage.getItem('preview-mode') === 'true' || false,
        cheatMode: localStorage.getItem('cheat-mode') === 'true' || false,
        isAppLoading: true,
        config: {
            features: {},
            inCodeLabels: {},
            playerProfileFields: {},
            playerProfileDropdownFields: {},
            theming: {},
            branding: {}
        },
        timers: {}
    },
    mutations: {
        setLanguage: (state, language) => {
            state.language = language
            localStorage.setItem('language', language)
        },
        setConfig(state, config) {
            state.config = config
        },
        setIsAppLoading(state, isAppLoading) {
            state.isAppLoading = isAppLoading
        },
        setPreviewMode(state, previewMode) {
            state.previewMode = previewMode
            localStorage.setItem('preview-mode', previewMode)
        },
        setCheatMode(state, cheatMode) {
            state.cheatMode = cheatMode
            localStorage.setItem('cheat-mode', cheatMode)
        },
        startTimer: (state, taskId, duration, endOfTimerCallback) => {
            state.timers[taskId] = { secondsLeft: 60 }
            state.timers[taskId] = {
                ...state.timers[taskId],
                interval: setInterval(() => {
                    if (state.timers[taskId].secondsLeft > 0) {
                        state.timers[taskId] = { ...state.timers[taskId], secondsLeft: state.timers[taskId].secondsLeft - 1 }
                    } else {
                        clearInterval(state.timers[taskId].interval)
                        state.timers[taskId].secondsLeft = 0
                        if (typeof (endOfTimerCallback) === 'function') {
                            endOfTimerCallback()
                        }
                    }
                }, 1000)
            }
        },
        killTimer: (state, taskId, endOfTimerCallback) => {
            clearInterval(state.timers[taskId].interval)
            state.timers[taskId].secondsLeft = 0
            if (typeof (endOfTimerCallback) === 'function') {
                endOfTimerCallback()
            }
        }
    },
    actions: {
        fetchConfig({ commit }) {
            configService.getConfig().then((res) => {
                commit('setConfig', res.data)
            }).then(() => {
                commit('setIsAppLoading', false)
            })
        },
        initLanguage({ commit }, language) {
            commit('setLanguage', language)
        },
        startTimer({ commit }, taskId, duration, endOfTimerCallback) {
            commit('startTimer', taskId, duration, endOfTimerCallback)
        },
        killTimer({ commit }, taskId, callback) {
            commit('killTimer', taskId, callback)
        }
    }
}
