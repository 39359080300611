import Vue from 'vue'
import Router from 'vue-router'

import 'nprogress/nprogress.css'
import NProgress from 'nprogress'
import vueConfig from '@/../vue.config'
import missionService from '@/api/mission'
import store from '@/store'
import jwtUtils from '@/utils/jwt-utils'

Vue.use(NProgress)
Vue.use(Router)

let router = new Router({
    mode: 'history',
    base: vueConfig.publicPath,
    routes: [
        {
            path: '',
            redirect: 'home',
            meta: {
                title: 'Home',
                fullScreen: true
            }
        },
        {
            path: '/home',
            alias: '/missions',
            name: 'home',
            component: () => import('@/components/routes/HomeRoute'),
            meta: {
                title: 'Home'
            }
        },
        {
            path: '/edit-profile',
            name: 'edit-profile',
            component: () => import('@/components/routes/EditProfileRoute'),
            meta: {
                title: 'Edit Profile'
            }
        },
        {
            path: '/missions/:missionId/tasks/:taskId/edit-profile',
            name: 'edit-profile-as-task',
            component: () => import('@/components/routes/EditProfileRoute'),
            meta: {
                title: 'Edit Profile',
                isTask: true
            }
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import('@/components/routes/ForgotPasswordRoute'),
            meta: {
                title: 'Forgot Password',
                notSecure: true,
                fullScreen: true
            }
        },
        {
            path: '/reset-password/:resetToken',
            name: 'rest-password',
            component: () => import('@/components/routes/NewPasswordRoute'),
            meta: {
                title: 'Reset Password',
                notSecure: true,
                fullScreen: true
            }
        },
        {
            path: '/register',
            name: 'Email Register',
            component: () => import('@/components/routes/NewPasswordRoute'),
            meta: {
                title: 'Register',
                notSecure: true,
                fullScreen: true
            }
        },
        {
            path: '/register/:inviteCode',
            name: 'Invitation Register',
            component: () => import('@/components/routes/NewPasswordRoute'),
            meta: {
                title: 'Register',
                notSecure: true,
                fullScreen: true
            }
        },
        {
            path: '/token-login/:code',
            name: 'token-login',
            component: () => import('@/components/routes/TokenLoginRoute'),
            meta: {
                title: 'Token Login',
                notSecure: true,
                fullScreen: true
            }
        },
        {
            path: '/missions/:missionId',
            name: 'missions',
            component: () => import('@/components/routes/MissionRoute'),
            meta: {
                title: 'Mission'
            }
        },
        {
            path: '/task-summary/:missionId',
            name: 'task-summary',
            component: () => import('@/components/routes/TaskSummaryRoute'),
            meta: {
                title: 'Task Summary'
            }
        },
        {
            path: '/prizes',
            name: 'prizes',
            component: () => import('@/components/routes/PrizesRoute'),
            meta: {
                title: 'Prizes'
            }
        },
        {
            path: '/library',
            name: 'library',
            component: () => import('@/components/routes/LibraryRoute'),
            meta: {
                title: 'Library'
            }
        },
        {
            path: '/missions/:missionId/tasks/:taskId/library',
            name: 'library-as-task',
            component: () => import('@/components/routes/LibraryRoute'),
            meta: {
                title: 'Library',
                isTask: true
            }
        },
        {
            path: '/articles/:articleId',
            name: 'article',
            component: () => import('@/components/routes/ArticleRoute'),
            meta: {
                title: 'Article'
            }
        },
        {
            path: '/surveys',
            name: 'surveys',
            component: () => import('@/components/routes/SurveysRoute'),
            meta: {
                title: 'Surveys'
            }
        },
        {
            path: '/missions/:missionId/tasks/:taskId/surveys/:surveyId',
            name: 'surveys-as-task',
            component: () => import('@/components/Survey'),
            meta: {
                title: 'Surveys',
                isTask: true
            }
        },
        {
            path: '/surveys/:surveyId',
            component: () => import('@/components/Survey'),
            name: 'survey'
        },
        {
            path: '/submit-code',
            name: 'submit-code',
            component: () => import('@/components/routes/SubmitCodeRoute'),
            meta: {
                title: 'Submit Code',
                fullScreen: true
            }
        },
        {
            path: '/leaderboard',
            name: 'leaderboard',
            component: () => import('@/components/routes/LeaderBoardRoute'),
            meta: {
                title: 'LeaderBoard'
            }
        },
        {
            path: '/missions/:missionId/tasks/:taskId',
            name: 'tasks',
            component: () => import('@/components/routes/TaskRoute'),
            meta: {
                title: 'Task',
                fullScreen: true,
                isTask: true
            }
        },
        {
            path: '/missions/:missionId/tasks/:taskId/daily-quiz',
            name: 'tasks',
            component: () => import('@/components/routes/DailyQuizRoute'),
            meta: {
                title: 'Task',
                fullScreen: false,
                isTask: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/components/routes/LoginRoute'),
            meta: {
                title: 'Login',
                fullScreen: true
            }
        },
        {
            path: '*',
            name: '404 Not Found',
            component: () => import('@/components/routes/NotFoundRoute'),
            meta: {
                title: 'Not Found'
            }
        }
    ]
})

router.beforeResolve((to, from, next) => {
    NProgress.start()
    document.title = 'Gamification Platform | ' + to.meta.title
    next()
})

router.beforeResolve((to, from, next) => {
    if (to.meta.isTask) {
        let missionId = parseInt(to.params.missionId)
        let taskId = parseInt(to.params.taskId)
        if (!store.getters.isPreviewMode && !store.getters.isTaskCompleted(missionId, taskId)) {
            missionService.startTask(missionId, taskId)
                .then(() => {
                    console.log('task with ' + taskId + ' was started')
                }, () => {
                    console.log('task with ' + taskId + ' is already finished or is not next')
                    next({ path: '/missions/' + missionId, replace: true })
                })
        }
    }
    next()
})

router.beforeEach((to, from, next) => {
    NProgress.start()
    if (to.query.lang === 'en') {
        store.commit('setLanguage', 'en')
    } else if (to.query.lang === 'el') {
        store.commit('setLanguage', 'el')
    }
    if (to.query.previewMode === 'true') {
        store.commit('setPreviewMode', true)
    } else if (to.query.previewMode === 'false') {
        store.commit('setPreviewMode', false)
    }
    if (to.query.cheatMode === 'true') {
        store.commit('setCheatMode', true)
    } else if (to.query.cheatMode === 'false') {
        store.commit('setCheatMode', false)
    }

    const isUserLoggedIn = store.getters.isUserLoggedIn
    const apiToken = localStorage.getItem('api-token')
    if (!isUserLoggedIn && to.meta.notSecure) {
        next()
    } else if ((!apiToken || !jwtUtils.isValid(apiToken)) && !(to.path === '/login' || to.path === '/forgot-password' || to.path === '/reset-password')) {
        store.dispatch('logout')
        next({ path: '/login', replace: true })
    } else if ((to.path === '/login' || to.path.startsWith('/register') || to.path === '/forgot-password') && isUserLoggedIn && jwtUtils.isValid(apiToken)) {
        next({ path: '/home', replace: true })
    } else {
        next()
    }
})

router.afterEach(() => {
    NProgress.done()
})

export default router
