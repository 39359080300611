<template>
    <div class="sidebar">
        <router-link to="/home" class="desktop-only">
            <b-img
                :src="branding.primaryLogoUrl"
                class="pt-2 logo"
                alt=" "
            />
        </router-link>
        <div class="sidebar-header-mobile">
            <div>
                <router-link to="/edit-profile" class="avatar-link">
                    <avatar :avatar="player.avatar? player.avatar : null" />
                </router-link>
            </div>
            <div class="mobile-only">
                <navigation
                    :player="player"
                    :missions="missions"
                    class="profile-nav-background"
                />
            </div>
        </div>
        <div class="desktop-only">
            <div class="player-info border-bottom">
                <div class="user-name">
                    {{ player.firstName }}
                </div>
                <div class="email">
                    {{ player.email }}
                </div>
            </div>
            <div class="player-status border-bottom">
                <div class="player-points">
                    <div class="value">{{ totalPoints }}</div>
                    <div class="title">{{ $t('sidebar.points') }}</div>
                </div>
                <div class="player-rank">
                    <div class="value">{{ currentPlayerPosition }}</div>
                    <div class="title">{{ $t('sidebar.rank') }}</div>
                </div>
            </div>
            <div class="progress-bar-container completed-missions border-bottom">
                <div
                    class="progress-bar-label"
                >
                    <span>
                        {{ $t('sidebar.missions') }}
                    </span>
                    <span>
                        {{ completedMissionCount }}/{{ totalMissionCount }}
                    </span>
                </div>
                <div class="progress-bar-background pb-2">
                    <div
                        class="progress-color"
                        :style="{ width: progressBars(completedMissionCount, totalMissionCount) }"
                    />
                </div>
            </div>
            <div class="progress-bar-container completed-tasks border-bottom">
                <div class="progress-bar-label">
                    <span>
                        {{ $t('sidebar.tasks') }}
                    </span>
                    <span>
                        {{ player.completedTasks }}/{{ totalTaskCount }}
                    </span>
                </div>
                <div class="progress-bar-background pb-2">
                    <div
                        class="progress-color"
                        :style="{ width: progressBars(player.completedTasks, totalTaskCount) }"
                    />
                </div>

            </div>
            <div v-if="player.playerBadges && player.playerBadges.length > 0" class="player-badges border-bottom">
                <div class="text-left">
                    {{ $t('sidebar.badges') }}
                </div>
                <div class="text-left">
                    <badge class="badge" :badges="player.playerBadges" />
                </div>
            </div>
            <div class="terms-instructions-container">
                <a v-if="branding.termsAndGdprPdfUrl" :href="branding.termsAndGdprPdfUrl" target="_blank" class="text-left terms">
                    {{ $t('sidebar.terms') }}
                </a>
                <a v-if="branding.contestPdfUrl" :href="branding.contestPdfUrl" target="_blank" class="text-left terms">
                    {{ $t('sidebar.contestPdfUrl') }}
                </a>
                <a v-if="branding.manualPdfUrl" :href="branding.manualPdfUrl" target="_blank" class="text-left instructions">
                    {{ $t('sidebar.instructions') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import config from '@/mixins/config'
    import Badge from '@/components/Badge'
    import Navigation from '@/components/Navigation'
    import Avatar from '@/components/Avatar'

    export default {
        name: 'ProfileSideBar',
        components: { Badge, Avatar, Navigation },
        mixins: [ config ],
        props: {
            missions: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            branding() {
                return this.$store.getters.getBranding
            },
            player() {
                return this.$store.getters.getCurrentUser
            },
            totalPoints() {
                return this.player.totalScore
            },
            totalMissionCount() {
                return this.missions.length
            },
            ranks() {
                return this.$store.getters.getRanks
            },
            currentPlayerPosition() {
                if (this.ranks.find(rank => rank.id === this.player.id)) {
                    return this.ranks.find(rank => rank.id === this.player.id).rank
                }
                return 0
            },
            totalTaskCount() {
                let taskCount = 0
                for (let mission of this.missions) {
                    taskCount += mission.tasks.length
                }
                return taskCount
            },
            completedMissionCount() {
                let completedMissionCount = 0
                for (let mission of this.missions) {
                    if (mission.completed) {
                        completedMissionCount += 1
                    }
                }
                return completedMissionCount
            }
        },
        methods: {
            progressBars(completedCount, totalCount) {
                return completedCount && totalCount ? `${(completedCount / totalCount) * 100}%` : '0%'
            }
        }
    }
</script>

<style lang="scss" scoped>
.instructions,
.terms {
    text-decoration: none;
    color: var(--white);
    transition: all 0.1s ease-in;
    cursor: pointer;
    text-align: center;
}

.profile-side-bar {
    color: var(--white);
    background-color: var(--sidebar-background);
    font-weight: 700;
    max-width: 100%;
    height: 100%;
}

.user-name {
    color: var(--sidebar-text);
    font-size: 1.7em;
}

.user-name svg {
    font-size: 45%;
    color: rgb(255 255 255 / 40%);
}

.border-bottom {
    border-bottom: solid 1px rgb(255 255 255 / 50%);
    padding-bottom: 1rem;
}

.progress-bar-background {
    height: 20px;
    background-color: var(--white);
    border-radius: 20px;
    padding: 5px;
}

.progress-color {
    background-color: var(--info-color);
    height: 10px;
    border-radius: 20px;
    box-shadow: 5px 3px 27px -3px var(--black);
}

.text-margin {
    margin-right: 24%;
}

.email {
    color: var(--sidebar-text);
}

a:hover { text-decoration: none; }

img.logo {
    vertical-align: text-top;
    max-height: 60px;
    max-width: 100%;
}

.profile-side-bar .navigation {
    background-color: var(--sidebar-background);
}

.sidebar-header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
}

.avatar-link {
    align-self: flex-start;
    max-width: 50px;
    display: block;
}

@media screen and (min-width: 992px) {
    .sidebar-header-mobile {
        justify-content: center;
        padding: 1rem 0;
    }

    .avatar-link {
        max-width: 110px;
    }

    .player-info {
        padding: 1rem 0;
    }

    .player-status {
        padding: 1rem 0;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .player-status .value {
        color: var(--primary-color);
        font-size: 1.75rem;
    }

    .player-status .title {
        color: var(--sidebar-text);
        font-size: 1rem;
    }

    .progress-bar-container {
        padding: 2rem 7%;
    }

    .player-badges {
        padding: 1rem 7%;
    }

    .terms-instructions-container {
        padding: 1rem 7%;
        display: flex;
        flex-direction: column;
    }

    .progress-bar-label {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
    }
}
</style>

<style lang="scss">
.profile-nav-background .nav-item,
.profile-nav-background .nav-link {
    background-color: var(--white);
}

.profile-nav-background .b-nav-dropdown .nav-link {
    background: transparent !important;
}

.badge img {
    width: 100%;
    max-width: 80px;
    min-width: 80px;
}

.badge .badge-text {
    right: 5%;
    top: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
}

</style>
