<template>
    <b-navbar v-if="inCodeLabels" fixed toggleable="lg" class="navigation p-0">
        <b-navbar-toggle id="navbarToggler" target="nav-collapse" class="toggler ">
            <template #default>
                <burger-svg />
            </template>
        </b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="mr-auto main-nav">
                <b-nav-item :to="'/home'">
                    <home-svg class="nav-icon" />
                    <span class="nav-item-text ml-1 mr-1">{{ $t('navigation.home') }}</span>
                </b-nav-item>
                <b-nav-item-dropdown class="mission-dropdown">
                    <template slot="button-content">
                        <task-svg class="nav-icon" />
                        <span class="nav-item-text mr-1">{{ $t('navigation.taskSummary') }}</span>
                    </template>
                    <b-dropdown-item
                        v-for="mission in missions"
                        :key="mission.id"
                        :disabled="mission.locked"
                        :to="`/task-summary/${mission.id}`"
                    >
                        <div class="nav-item-text">
                            {{ mission.alias }}
                        </div>
                        <span v-if="mission.locked" class="badge badge-danger">{{ $t('navigation.locked') }}</span>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item :to="'/leaderboard'">
                    <leader-board-svg class="nav-icon" />
                    <span class="nav-item-text ml-1 mr-1">{{ $t('navigation.leaderBoard') }}</span>
                </b-nav-item>
                <b-nav-item :to="'/library'">
                    <library-svg class="nav-icon" />
                    <span class="nav-item-text ml-1 mr-1">{{ $t('navigation.library') }}</span>
                </b-nav-item>
                <b-nav-item v-if="featureConfig.rewardsEnabled" :to="'/prizes'">
                    <rewards-svg class="nav-icon" />
                    <span class="nav-item-text mr-1">{{ $t('navigation.prizes') }}</span>
                </b-nav-item>
                <b-nav-item-dropdown v-if="featureConfig.bonusActivitiesEnabled" class="bonus-dropdown">
                    <template slot="button-content">
                        <bonus-svg class="nav-icon" />
                        <span class="nav-item-text ml-1 mr-1">{{ $t('navigation.bonus') }}</span>
                    </template>
                    <b-dropdown-item
                        v-if="featureConfig.inCodeEnabled"
                        class="bonus-dropdown-item"
                        :to="`/submit-code`"
                    >
                        <div class="icon-container star">
                            <star-svg class="nav-dropdown-icon" />
                        </div>
                        <span>{{ labelText('mainTitle') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="featureConfig.surveysEnabled"
                        class="bonus-dropdown-item"
                        :to="`/surveys`"
                    >
                        <div class="icon-container smile">
                            <smile-svg class="nav-dropdown-icon" />
                        </div>
                        <span>{{ $t('navigation.survey') }}</span>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item class="mobile-logout" @click="logout">
                    <logout-svg class="nav-icon" />
                    <span class="nav-item-text mr-1">{{ $t('navigation.logout') }}</span>
                </b-nav-item>

            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="avatar-menu">
                <b-nav-item-dropdown right class="avatar-link">
                    <template slot="button-content">
                        <avatar
                            :avatar="player.avatar? player.avatar : null"
                            avatar-width="45px"
                        />
                    </template>
                    <b-dropdown-item
                        :to="`/edit-profile`"
                        class="edit-profile"
                    >
                        <edit-profile-svg class="profile-dropdown-icon" />
                        <span class="nav-item-text">{{ $t('navigation.editProfile') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                        class="logout"
                        @click="logout"
                    >
                        <logout-svg class="profile-dropdown-icon" />
                        <span class="nav-item-text">
                            {{ $t('navigation.logout') }}
                        </span>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
    import config from '@/mixins/config'
    import HomeSvg from '@/components/svg/HomeSVG'
    import TaskSvg from '@/components/svg/TaskSVG'
    import LeaderBoardSvg from '@/components/svg/LeaderBoardSVG'
    import LibrarySvg from '@/components/svg/LibrarySVG'
    import RewardsSvg from '@/components/svg/RewardsSVG'
    import BonusSvg from '@/components/svg/BonusSVG'
    import StarSvg from '@/components/svg/StarSVG'
    import SmileSvg from '@/components/svg/SmileSVG'
    import BurgerSvg from '@/components/svg/BurgerSVG'
    import LogoutSvg from '@/components/svg/LogoutSVG'
    import EditProfileSvg from '@/components/svg/EditProfileSVG'
    import Avatar from '@/components/Avatar'

    export default {
        name: 'Navigation',
        components: {
            HomeSvg,
            TaskSvg,
            LeaderBoardSvg,
            LibrarySvg,
            RewardsSvg,
            BonusSvg,
            StarSvg,
            SmileSvg,
            BurgerSvg,
            LogoutSvg,
            EditProfileSvg,
            Avatar
        },
        mixins: [config],
        props: {
            player: {
                type: Object,
                default: () => {}
            },
            missions: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            inCodeLabels() {
                return this.$store.getters.getInCodeLabels
            },
            featureConfig() {
                return this.$store.getters.getConfigFeatures
            }
        },
        methods: {
            labelText(fieldName) {
                return this.inCodeLabels ? this.inCodeLabels[fieldName] : ''
            },
            logout() {
                this.$store.dispatch('logout')
                // TODO remove window.reload()!
                window.location.reload()
            }
        }
    }
</script>

<style lang="scss" scoped>
.toggler {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
}

.navbar-collapse {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    transform: translateY(5%);
}

.navigation {
    font-size: 14px;
    font-weight: 700;
    background-color: var(--navigation-background);
    position: relative;
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.navbar-light .navbar-nav .nav-link {
    color: var(--navigation-text) !important;
}

.navbar-nav .main-nav {
    overflow: hidden;
}

.nav-item-text {
    color: var(--navigation-text) !important;
    font-weight: 700;
    border: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}

.nav-item.mobile-logout {
    display: flex !important;
    margin: 0 auto;
}

.icon-container {
    background: var(--primary-color);
    border-radius: 50%;
    display: grid;
    place-items: center;
    padding: 0.25rem;
}

.nav-icon {
    max-width: 35px;
    max-height: 35px;
    margin-right: 0.25rem;
}

.nav-dropdown-icon {
    width: 50px;
    height: auto;
}

.profile-dropdown-icon {
    width: 30px;
    height: auto;
}

.avatar-menu {
    display: none;
}

@media screen and (min-width: 992px) {
    .nav-item.mobile-logout {
        display: none !important;
    }

    .navbar-collapse {
        position: relative;
        right: unset;
        left: 0;
        transform: translateY(0);
        width: 100%;
    }

    .avatar-menu {
        display: flex;
        margin-left: auto;
    }
}

@media screen and (min-width: 1199px) {
    .navbar-collapse {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 10;
        transform: translateY(0);
    }
}

</style>

<style lang="scss">
.navbar-light .navbar-toggler {
    border-color: transparent !important;
    padding: 0;
}

.dropdown-item {
    color: var(--navigation-text) !important;
    padding: 0.5rem 1rem !important;
    display: flex !important;
    gap: 0.5rem;
    align-items: center;
}

.bonus-dropdown-item {
    display: flex !important;
    gap: 0.5rem;
}

.dropdown-item span {
    white-space: normal;
    text-align: left;
    display: block;
}

.dropdown-item.disabled {
    color: var(--disabled-color) !important;
}

.nav-link {
    padding: 1rem 0.75rem !important;
    transition: all 0.15s ease-in;
    width: 100%;
}

.nav-item,
.nav-link {
    display: flex !important;
    align-items: center;
    flex-wrap: nowrap !important;
    justify-content: flex-start;
    height: 70px;
    border-bottom: 1px solid var(--light-secondary-color);
}

.mission-dropdown .dropdown-menu {
    width: 130%;
    position: absolute !important;
    top: 100%;
    right: 10%;
    left: unset;
}

.bonus-dropdown .dropdown-menu {
    width: 130%;
    position: absolute !important;
    top: 100%;
    right: 20%;
    left: unset;
}

.mission-dropdown .dropdown-item {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}

.mobile-logout {
    text-transform: uppercase;
    color: var(--white);
    width: 100%;
}

@media screen and (min-width: 768px) {
    .nav-link {
        padding: 1rem 0.75rem !important;
        transition: all 0.15s ease-in;
        width: 100%;
    }

    .nav-item,
    .nav-link {
        display: flex !important;
        align-items: center;
        flex-wrap: nowrap !important;
        height: 70px;
    }

    .avatar-link:hover {
        background-color: none !important;
    }

    .mission-dropdown .dropdown-menu {
        width: 250%;
        right: 20%;
    }

    .bonus-dropdown .dropdown-menu {
        width: 130%;
    }
}

@media screen and (min-width: 992px) {
    .nav-link:hover,
    .dropdown-item:hover {
        background-color: var(--light-highlight-color) !important;
    }

    .mission-dropdown .dropdown-menu {
        width: 300%;
        right: unset;
        left: 0;
    }

    .bonus-dropdown .dropdown-menu {
        width: 200%;
        right: 0;
        top: 100%;
    }

    .bonus-dropdown .dropdown-item:active {
        background: var(--white);
        color: var(--disabled-color);
    }
}

.dropdown-toggle {
    white-space: normal !important;
}

.dropdown-toggle::after {
    display: none !important;
}

.dropdown-toggle span::after {
    display: inline-block;
    margin-left: 0.5rem;
    content: "";
    border-top: 0.5em solid;
    border-right: 0.5em solid transparent;
    border-bottom: 0;
    border-left: 0.5em solid transparent;
    color: var(--logo-highlight-color);
}

.dropdown-item:active {
    background: var(--white);
    color: var(--disabled-color);
}

.navigation .dropdown-menu {
    outline: none;
}

.navigation .dropdown-item:active {
    background: var(--white);
    color: var(--disabled-color);
}

</style>
