import axios from 'axios'
import store from '@/store'
import router from '@/router'
import jwtUtils from '@/utils/jwt-utils'
const API_URL = process.env.VUE_APP_API_HOST_DOMAIN + process.env.VUE_APP_API_BASE_PATH

export default {
    post(urlPath, data) {
        return this.request(urlPath, 'POST', data)
    },
    put(urlPath, data) {
        return this.request(urlPath, 'PUT', data)
    },
    get(urlPath) {
        return this.request(urlPath, 'GET', null)
    },
    uploadFile(urlPath, file) {
        const formData = new FormData()
        formData.append('file', file, 'avatar.png')
        return this.request(
            urlPath,
            'POST',
            formData,
            {
                'Content-Type': 'multipart/form-data'
            }
        )
    },
    request(urlPath, method, data, headers) {
        method = method || 'POST'

        headers = headers || {
            'Content-Type': 'application/json; charset=utf-8'
        }
        const apiToken = localStorage.getItem('api-token')

        if (apiToken) {
            if (!jwtUtils.isValid(apiToken)) {
                store.dispatch('logout')
                router.push('/login')
            }
            headers = {
                ...headers,
                'Authorization': 'Bearer ' + apiToken
            }
        }

        return new Promise((resolve, reject) => {
            axios({
                url: API_URL + '/' + urlPath,
                data: data,
                method: method,
                headers: headers
            }).then(response => {
                if (response.data.content) {
                    const obj = { data: response.data.content, ...response.data }
                    delete obj.content
                    resolve(obj)
                } else {
                    resolve({ data: response.data })
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }
}
